import { PlusIcon } from '@heroicons/react/20/solid'

export default function AddDivider(props: { onSubmit: Function }) {
    const { onSubmit } = props;

    return (
        <div className="relative">
            <div className="absolute inset-0 flex items-center" aria-hidden="true">
                <div className="w-full border-t border-gray-300" />
            </div>
            <div className="relative flex justify-center">
                <button
                    onClick={() => onSubmit()}
                    type="button"
                    className="inline-flex items-center rounded-full border border-gray-300 bg-white px-4 py-1.5 text-sm font-medium leading-5 text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none"
                >
                    <PlusIcon className="-ml-1.5 mr-1 h-5 w-5 text-gray-400" aria-hidden="true" />
                    <span >Add Test Run</span>
                </button>
            </div>
        </div>
    )
}
