import { useRef, useImperativeHandle, forwardRef, useState } from "react";

import CodeMirror, { ReactCodeMirrorRef } from '@uiw/react-codemirror';
import { javascript } from '@codemirror/lang-javascript';
import TestResult, { ETestResultState, ITestResultProps } from "./TestResult";
import { XMarkIcon } from "@heroicons/react/20/solid";

const WORKER_TIMEOUT = 15_000;
const duration = 5_000;

export interface ICodeAreaProps {
    onRemove: (string: string) => void;
    initialValue?: string;
    usedKey: string;
    runResult: { max: number } | null;
}

export type CodeAreaHandler = {
    startTest: () => Promise<number>;
    getValue: () => string;
}

function buildCodeFragment(code: string, beforeCode = '') {
    let fragment = `(() => {
        ${beforeCode}
        let ops = 0;
        const start = performance.now();
        while((performance.now() - start) < ${duration}) {
            ${code}
            ops++;
        }
        postMessage(ops);
      })()`;

    return fragment;
}

function createWebWorker(script: string) {
    var blob = new Blob([
        script
    ], { type: "text/javascript" })

    return new Worker(window.URL.createObjectURL(blob));
}

function percentDifference(a: number, b: number): number {
    return Math.abs(a - b) / ((a + b) / 2);
}

const CodeArea = forwardRef<CodeAreaHandler, ICodeAreaProps>((props, ref) => {
    const { runResult, onRemove, initialValue } = props;

    const codeMirrorRef = useRef<ReactCodeMirrorRef>(null);
    const [value, setValue] = useState(initialValue || '');
    const [result, setResult] = useState<ITestResultProps>({
        state: ETestResultState.NotStarted
    });

    const reportResult = (result: ITestResultProps, worker: Worker) => {
        setResult(result);
        worker.terminate();
    }

    const startTest = () => {
        return new Promise<number>((resolve, reject) => {
            setResult({
                state: ETestResultState.Pending,
                data: 'Pending'
            });

            const timeout = setTimeout(() => {
                reportResult({
                    state: ETestResultState.Error,
                    data: 'Timeout'
                }, worker);
            }, WORKER_TIMEOUT);

            const fragment = buildCodeFragment(value);
            const worker = createWebWorker(fragment);
            worker.onmessage = (ev) => {
                const value = Math.round(ev.data / duration * 1000);
                reportResult({
                    state: ETestResultState.Complete,
                    data: value.toString()
                }, worker);
                clearTimeout(timeout);
                resolve(value);
            };

            worker.onerror = (ev) => {
                reportResult({
                    state: ETestResultState.Error,
                    data: ev.message
                }, worker);
                clearTimeout(timeout);
                resolve(Infinity);
            }
        });
    }

    useImperativeHandle(ref, () => ({
        startTest() {
            return startTest();
        },
        getValue() {
            return value;
        }
    }));

    const bestRun = runResult?.max.toString() === result.data;
    let slower = 0;

    if (runResult?.max) {
        slower = percentDifference(runResult.max, parseFloat(result.data!));
    }

    let borderColor = 'border-stone-300';
    if (result.state === ETestResultState.Complete) {
        borderColor = bestRun ? 'border-green-500' : 'border-red-500'
    }

    return (
        <div className={`flex-col md:grid md:grid-cols-3 relative`}>
            <div className={`w-full rounded-md border-2 border-b-0 md:border-b-2 rounded-b-none md:rounded-bl-md md:border-r-2 md:rounded-r-none ${borderColor} p-[4px] col-span-2`}>
                <CodeMirror
                    onChange={(value) => setValue(value)}
                    value={value}
                    ref={codeMirrorRef}
                    height="150px"
                    extensions={[javascript()]} />
            </div>
            <div className={`border-2 rounded-md rounded-t-none md:rounded-tr-md md:border-l-0 md:rounded-l-none border-stone-300 h-24 md:h-auto ${borderColor}`}>
                <TestResult slower={slower} result={result} />
            </div>
            <div onClick={() => onRemove(props.usedKey)} className="absolute top-0 right-0 w-6 h-6 text-gray-500 hover:text-red-500 hover:cursor-pointer bg-white overflow-hidden border-2 rounded-md border-stone-300 rounded-tl-none rounded-br-none"><XMarkIcon /></div>
        </div>
    )
});

export default CodeArea;