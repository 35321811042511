import AddDivider from "./components/AddDivider";
import CodeArea, { CodeAreaHandler } from "./components/CodeArea";

import { useState, createRef, useEffect } from 'react';
import Navbar from "./components/Navbar";

const hash = window.location.hash;
let initialState: string[] = ['', ''];
if (hash) {
  initialState = JSON.parse(atob(hash.substring(1)));
}

interface ICodeAreaState {
  key: string;
  ref: React.RefObject<CodeAreaHandler>;
  initialValue?: string;
}

function App() {
  const [codeAreas, setCodeAreas] = useState<ICodeAreaState[]>([]);
  const [running, setRunning] = useState(false);

  useEffect(() => {
    const newAreas: ICodeAreaState[] = [];

    for (const s of initialState) {
      const ref = createRef<CodeAreaHandler>();
      const tag = Date.now().toString() + s;
      newAreas.push({
        ref,
        key: tag.toString(),
        initialValue: s
      });
    }

    setCodeAreas(newAreas);
  }, [])

  const [runResult, setRunResult] = useState<{ max: number } | null>(null);

  const runTests = () => {
    if (running) return;
    setRunning(true);
    Promise.all(codeAreas.map(({ ref }) => ref.current?.startTest())).then((results) => {

      let max = -Infinity;
      for (const result of results) {
        if (result !== undefined)
          max = Math.max(result, max);
      }
      setRunResult({ max });
      setRunning(false);
    });
  }

  const share = () => {
    const state = btoa(JSON.stringify(codeAreas.map(({ ref }) => ref.current?.getValue())));
    window.location.hash = state;
  }

  const deleteTest = (keyToDelete: string) => {
    const newCodeAreas = [...codeAreas];
    console.log(codeAreas);
    const index = newCodeAreas.findIndex(({ key }) => {
      return key === keyToDelete;
    })
    newCodeAreas.splice(index, 1);
    setCodeAreas(newCodeAreas);
  };

  const createTest = (initialValue?: string) => {
    const ref = createRef<CodeAreaHandler>();
    const tag = Date.now().toString() + initialValue;
    setCodeAreas(prev => [...prev, { key: tag.toString(), ref, initialValue }]);
  };

  return (
    <div>
      <Navbar />
      <div className="container mx-auto px-4">
        <div className="flex justify-end items-center gap-2 my-4">
          <button disabled={running} className="bg-blue-500 hover:bg-blue-700 text-white py-1 px-4 rounded-md disabled:bg-gray-500" onClick={runTests}>Run Tests</button>
          <button className="border hover:bg-slate-50 py-1 px-4 rounded-md" onClick={share}>Share</button>
        </div>
        {/* <div>
          <BeforeCode />
        </div> */}
        <div className="flex flex-col gap-4 mb-4">
          {codeAreas.map(c => {
            return (
              <CodeArea runResult={runResult} initialValue={c.initialValue} ref={c.ref} key={c.key} usedKey={c.key} onRemove={() => deleteTest(c.key)} />
            );
          })}
        </div>
        <AddDivider onSubmit={createTest} />
      </div>
    </div>
  );
}

export default App;
